/**
 *
 * Create map object:
 * const m = new PolygonMap();
 *
 * m.setupMap(id)
 * Fill the google map div width id. This must be done after draw of page is finished
 *
 * m.mapAddPolygon(data, editable, callback)
 * data = null - start new polygon
 * data = {zoom: zoom, center: {lat: lat, lng: lng}, coords: [{lat, lng}]}
 * editable = true|false
 * callback = return the data object when it changes
 */

import mapStyles from "./map-styles";

class PolygonMap {
    constructor() {
        this.map = null;
        this.polygons = [];
    }

    setupMap(id, options) {
        this.map = new google.maps.Map(document.getElementById(id), {
            zoom: 10,
            center: {lat: 58.839317, lng: 5.683555},
            styles: mapStyles,
            mapTypeId: options.mapTypeId || 'roadmap'
        });
    }

    mapAddPolygon(data, editable, callback, clickHandler) {
        let self = this;
        let poly;
        let coords;
        for (let polygon of this.polygons) {
            polygon.setMap(null);
        }
        self.polygons = [];
        if (data === null) {
            let bounds = self.map.getBounds();

            if (bounds) {
                let northEast = bounds.getNorthEast();
                let southWest = bounds.getSouthWest();
                coords = [];
                let lat_padding = (northEast.lat() - southWest.lat()) / 5;
                let lng_padding = (northEast.lng() - southWest.lng()) / 5;
                // Top middle
                coords.push({
                    lng: southWest.lng() + (northEast.lng() - southWest.lng()) / 2,
                    lat: northEast.lat() - lat_padding
                })
                // Bottom left
                coords.push({
                    lat: southWest.lat() + lat_padding, lng: southWest.lng() + lng_padding
                });
                // Bottom right
                coords.push({
                    lat: southWest.lat() + lat_padding, lng: northEast.lng() - lng_padding
                })
            }
        } else {
            if (data.center) {
                self.map.setCenter(data.center);
            }
            if(data.zoom) {
                self.map.setZoom(data.zoom);
            }

            coords = data.coords;
        }
        poly = new google.maps.Polygon({
            paths: [coords],
            strokeColor: '#ED962A',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#ED962A',
            fillOpacity: 0.8,
            editable: editable
        })
        poly.setMap(self.map);
        self.polygons.push(poly);

        google.maps.event.addListener(poly, "dragend", getPolygonCoords);
        google.maps.event.addListener(poly.getPath(), "insert_at", getPolygonCoords);
        google.maps.event.addListener(poly.getPath(), "remove_at", getPolygonCoords);
        google.maps.event.addListener(poly.getPath(), "set_at", getPolygonCoords);
        self.map.addListener('bounds_changed', getPolygonCoords)

        if (clickHandler) {
            google.maps.event.addListener(poly, "click", clickHandler);
        }

        function getPolygonCoords() {
            var len = poly.getPath().getLength();
            let points = [];
            for (var i = 0; i < len; i++) {
                const p = poly.getPath().getAt(i);
                points.push({lat: p.lat(), lng: p.lng()});
            }
            if (callback) {
                callback({
                    zoom: self.map.getZoom(),
                    center: {lat: self.map.getCenter().lat(), lng: self.map.getCenter().lng()},
                    coords: points
                });
            }
        }
    }

    getGoogleMap() {
        return this.map;
    }
}

export default PolygonMap;