import { intersectionObserve } from "./_intersection-observer";

$('#archive-fields .options-list .options a').click(function(e) {
    e.preventDefault();
    var $this = $(this);

    var href = e.target.href;
    var $subOption = $this.parents('.sub-option');
    var key = $subOption.data('option');

    updateOptionList(e.target, href, key, $this.data('slug'));
});

function updateOptionList(target, href, key, value, isSingle) {
    const $this = $(target);

    // Update history state
    if (window.history.pushState) {
        window.history.pushState({}, '', href);
    }

    // Toggle active class for the option
    $this.parent().toggleClass('active');

    // Update the options list
    var $optionList = $('.options-list');
    var newOptions = toggleFieldOption(key, value, isSingle);
    $optionList.data('options', serializeOptions(newOptions));

    // Update all option hrefs to match the new selection
    $optionList.find('.sub-option').each(function(i, option) {
        var $option = $(option);
        var key = $option.data('option');
        var options = $option.data('options');
        
        $option.find('a').each(function(j, a) {
            var $a = $(a);
            var slug = $a.data('slug');

            a.href = '?' + serializeOptions(toggleFieldOption(key, slug, isSingle));
        });

        if (options) {
            // Show selected options on mobile
            var currentOptions = newOptions[key];
            var names = [];
            for (var i = 0; i < currentOptions.length; i++) {
                var slug = currentOptions[i];
                var keys = Object.keys(options);
                for (var j = 0; j < keys.length; j++) {
                    var option = options[keys[j]];
                    if (option['slug'] === slug) {
                        names.push(option['name']);
                    }
                }
            }
            $option.find('.selected').text(names.join(', '));
        }
    });

    // Show loading indicator
    $('.loading-spinner').show();
    //$('.fields').hide();

    // Get the new field listing page, update the .fields div with the new html
    $.get(href, function(data) {
        var $html = $(data);
        var $newData = $('#archive-fields .fields-wrapper .fields', $html);
        $('#archive-fields .fields').remove();
        $('#archive-fields .fields-wrapper').prepend($newData);

        $('.loading-spinner').hide();
    });
}

$('#archive-fields .options-list .label a').click(function(e) {
    e.preventDefault();

    var $parentOption = $(this).parents('.sub-option').find('.options');
    var parentIsOpen = $parentOption.hasClass('open');

    $('.sub-option .options').removeClass('open');

    if (!parentIsOpen) {
        $parentOption.addClass('open');
    }

});

$('.change-options').click(function(e) {
    e.preventDefault()

    $('html, body').animate({
        scrollTop: 0
    }, 300);
});

function parseFieldOptions(str) {
    var components = str.split('&');

    var areaComponents = components[0].split('=');
    var areas = areaComponents[1].length > 0 ? areaComponents[1].split(',') : [];

    return {
        omrade: areas,
    }
}

function toggleFieldOption(type, option, isSingle) {
    let options = parseFieldOptions($('.options-list').data('options'));

    let list = options[type];

    if (isSingle) {
        list = [option];
    } else {
        const index = list ? list.indexOf('' + option) : -1;
        if (index !== -1) {
            list.splice(index, 1);
        } else {
            list.push(option);
        }
    }

    options[type] = list;
    return options;
}

function serializeOptions(options) {
    var keys = Object.keys(options);
    var components = [];
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        components.push(key + '=' + options[key].join(','));
    }

    return components.join('&');
}

var $stickyHeader = $('#archive-fields .sticky-header');
var $fieldOptions = $('#archive-fields .field-options');
if ($fieldOptions.length > 0) {
    intersectionObserve($fieldOptions[0], 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0) {
            $stickyHeader.removeClass('show');
        } else {
            $stickyHeader.addClass('show');
        }
    });

    /*
    $(window).scroll(function() {
        var scrollTop = $(this).scrollTop();

        if (scrollTop > 0) {
            $stickyHeader.addClass('show');
        } else {
            $stickyHeader.removeClass('show');
        }
    });
    */
}